import React, { useRef, useCallback } from 'react';
import { styled } from '@mui/material/styles';

const RightArrowIcon = styled('i')`
  display: block;
  height: 10px;
  width: 10px;
  transform: rotate(-45deg);
  border-color: ${(props) => props.theme.palette.primary.main};
  border-style: solid;
  border-width: 0px 2px 2px 0px;
  border-image: none 100% / 1 / 0 stretch;
  transition: all 0.25s;
`;

const LeftArrowIcon = styled(RightArrowIcon)`
  transform: rotate(135deg);
`;

const Carousel = styled('div')`
  position: relative;
  display: flex;
  align-items: stretch;
  margin: 0 ${(props) => props.theme.spacing(4)};
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: 0;
  }
`;

const Arrow = styled('button')<{
  position: 'left' | 'right';
  arrowBackground: string;
}>`
  border: none;
  outline: none;
  position: absolute;
  align-items: center;
  user-select: none;
  cursor: pointer;
  display: flex;
  padding: 0 1em;
  top: 0;
  height: 100%;
  margin: 0 -1em;
  ${(props) => props.position}: -23px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    display: none;
    padding: 0;
  }
  background: ${({ arrowBackground }) => arrowBackground};
  :focus,
  :hover {
    > * {
      box-shadow: 0px 0px 4px rgba(107, 106, 143, 0.75);
    }
    i {
      height: 20px;
      width: 20px;
      border-width: 0px 4px 4px 0px;
      box-shadow: none;
    }
  }
`;

const Items = styled('div')`
  width: 100%;
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  scroll-padding: ${(props) => props.theme.spacing(1.5)};
`;

export interface TestimonialProps {
  arrowRight?: React.ReactElement;
  arrowLeft?: React.ReactElement;
  arrowBackgroundRight?: string;
  arrowBackgroundLeft?: string;
  children?: React.ReactNode;
}

export const Slider: React.FC<TestimonialProps> = ({
  children,
  arrowRight = <RightArrowIcon />,
  arrowLeft = <LeftArrowIcon />,
  arrowBackgroundRight = 'transparent',
  arrowBackgroundLeft = 'transparent',
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const hasItems = React.Children.count(children);
  if (!hasItems) return null;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const scroll = useCallback(
    (backward = false) => {
      const width = sliderRef.current?.firstElementChild?.scrollWidth || 0;
      sliderRef.current?.scrollBy({
        left: backward ? width : -width,
        behavior: 'smooth',
      });
    },
    [sliderRef],
  );

  return (
    <Carousel role="presentation">
      <Arrow
        role="button"
        aria-label="Zurück"
        position="left"
        onClick={() => scroll()}
        onKeyDown={(event) => event.key === 'Enter' && scroll()}
        arrowBackground={arrowBackgroundLeft}
      >
        {arrowLeft}
      </Arrow>
      <Items tabIndex={-1} ref={sliderRef}>
        {children}
      </Items>
      <Arrow
        role="button"
        aria-label="Weiter"
        position="right"
        onClick={() => scroll(true)}
        onKeyDown={(event) => event.key === 'Enter' && scroll(true)}
        arrowBackground={arrowBackgroundRight}
      >
        {arrowRight}
      </Arrow>
    </Carousel>
  );
};
