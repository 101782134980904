import { styled } from '@mui/material/styles';

import { useEkomi } from 'contexts/EkomiContext';
import { CustomerRating } from './CustomerRating';

import { ICeEkomi } from 'types/generated/contentful';

const EkomiBackground = styled('div')(({ theme }) => ({
  // @ts-ignore:next-line
  backgroundColor: theme.palette.background.blue050,
}));

export interface EkomiProps extends ICeEkomi {
  headline: string;
}

export const Ekomi = (props: EkomiProps) => {
  const { headline = 'Das sagen unsere Kunden' } = props;

  // We get the Ekomi Data from the EkomiContext
  // so that the components below can be dumb.
  const {
    ekomi: { averageScore, testimonials },
  } = useEkomi();

  return (
    <EkomiBackground>
      <CustomerRating
        headline={headline}
        averageScore={averageScore}
        testimonials={testimonials}
      />
    </EkomiBackground>
  );
};
