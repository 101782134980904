import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { Testimonials } from './Testimonials';
import { EkomiSealWithRatings } from './EkomiSealWithRatings';
import { AverageScore, Testimonial } from 'types/ekomi';

const SliderWrapper = styled('div')`
  margin: 0 auto;
  width: 100%;
`;

const SealWrapper = styled('div')`
  width: 100%;
  margin: 0 auto 32px;
  padding-bottom: 32px;
`;

interface CustomerRatingProps {
  headline: string;
  averageScore: AverageScore;
  testimonials: Testimonial[];
}

export const CustomerRating = (props: CustomerRatingProps) => {
  const { headline, averageScore, testimonials } = props;

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h3"
        color="text.blue"
        mt={2}
        pt={4}
        pl={{ xs: 1, sm: 5, md: 8 }}
      >
        {headline}
      </Typography>
      <SliderWrapper>
        <Testimonials testimonials={testimonials} />
      </SliderWrapper>
      <SealWrapper>
        <EkomiSealWithRatings averageScore={averageScore} />
      </SealWrapper>
    </Container>
  );
};
