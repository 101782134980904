import type { Testimonial as TestimonialType } from 'types/ekomi';

import { styled } from '@mui/material/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Slider } from './Slider';
import { Testimonial } from './Testimonial';

const SliderContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}));

export interface TestimonialProps {
  testimonials?: TestimonialType[];
}

export const Testimonials = ({ testimonials }: TestimonialProps) => {
  const hasTestimonials = testimonials && testimonials.length !== 0;
  if (!hasTestimonials) return null;

  return (
    <Box py={1}>
      <SliderContainer maxWidth="lg">
        <Slider>
          {testimonials.map((testimonial) => (
            <Testimonial key={testimonial.submitted} data={testimonial} />
          ))}
        </Slider>
      </SliderContainer>
    </Box>
  );
};
