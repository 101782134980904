import Image from 'next/image';

export const SEAL_WIDTH = 74;
export const EKOMI_SEAL_BRONZE =
  'https://images.ctfassets.net/f6c1mtmq4qrb/78hg9n6YUJhsQyBCEcCnvF/7ab3060bc4fe80ecc1b92bc9ebeb556a/ekomi_bronze.png';
export const EKOMI_SEAL_SILVER =
  'https://images.ctfassets.net/f6c1mtmq4qrb/79UYXG30qrDAXaNgPzWywR/12788930e5d8d9c62088eb35225e2197/ekomi_share_silber_seal.png';
export const EKOMI_SEAL_GOLD =
  'https://images.ctfassets.net/f6c1mtmq4qrb/FeGZkTtJ3XvTdD8s4tdua/a5f559b13acde1752eb2a519b6af4c9b/ekomi_gold_siegel_finanzcheck_de.png';

interface EkomiSealProps {
  averageRating: number;
  width?: number;
}
export const EkomiSeal = (props: EkomiSealProps) => {
  const { averageRating, width = SEAL_WIDTH } = props;

  const sealUrl =
    averageRating >= 4.8
      ? EKOMI_SEAL_GOLD
      : averageRating >= 4.4
        ? EKOMI_SEAL_SILVER
        : EKOMI_SEAL_BRONZE;
  const sealText =
    averageRating >= 4.8
      ? 'eKomi Siegel Gold - FINANZCHECK.de'
      : averageRating >= 4.4
        ? 'eKomi Siegel Silber - FINANZCHECK.de'
        : 'eKomi Siegel Bronze - FINANZCHECK.de';

  return (
    <Image
      src={sealUrl}
      alt={sealText}
      height={width}
      width={width} // is 1:1 ratio
      priority={false}
    />
  );
};
