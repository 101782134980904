import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

import { EkomiSeal } from './EkomiSeal';
import { StarRating } from './StarRating';

import { AverageScore } from 'types/ekomi';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const SealWrapper = styled('div')`
  margin-right: 12px;
`;

const RatingWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StarWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -8px;
`;

interface EkomiSealWithRatingsProps {
  averageScore: AverageScore;
}
export const EkomiSealWithRatings = (props: EkomiSealWithRatingsProps) => {
  const { averageScore } = props;
  const { rounded_average, number_of_ratings } = averageScore;

  const NUMBER_STARS = 5;

  return (
    <Wrapper>
      <SealWrapper>
        <EkomiSeal averageRating={Number(rounded_average)} width={56} />
      </SealWrapper>
      <RatingWrapper>
        <Typography
          variant="body1"
          color="text.primary"
          mb={0}
          sx={{ fontWeight: 'bold' }}
        >
          FINANZCHECK.de
        </Typography>
        <StarWrapper>
          <StarRating stars={NUMBER_STARS} />
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ paddingTop: '8px', marginLeft: '4px' }}
          >
            <span itemProp="rating">{Number(rounded_average).toFixed(1)}</span>
            {`/${NUMBER_STARS}`}
          </Typography>
        </StarWrapper>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ marginTop: '-8px' }}
        >
          <span itemProp="votes">{number_of_ratings}</span> Kundenbewertungen
        </Typography>
      </RatingWrapper>
    </Wrapper>
  );
};
